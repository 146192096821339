import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { NavigationBar } from "./components/NavigationBar";
import { AuthContext } from "./context/AuthContext";

function PrivateOutlet() {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  return (
    <>
      <NavigationBar />
      <Outlet />
    </>
  );
}

export default PrivateOutlet;