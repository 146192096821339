import { Grid } from '@mui/material'
import recycle from '../assets/images/recycle.png'

export const LoadingSpinner = () => {
    return (
        <Grid container justifyContent="center">
            <img className="loading-spinner" src={recycle}></img>
            <Grid item xs={12} textAlign="center">
                <p>Loading...</p>
            </Grid>
        </Grid>
    )
}