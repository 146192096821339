/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information for other notification types
 */
export type NotificationSomethingElse = {
    'content-available'?: number;
    notification_type?: NotificationSomethingElse.notification_type;
};

export namespace NotificationSomethingElse {

    export enum notification_type {
        SOMETHING_ELSE = 'something_else',
    }


}

