import { Grid } from "@mui/material"
import { slide as SlideMenu } from 'react-burger-menu'
import yarnball from '../assets/images/yarnball.png';


export const NavigationBar = () => {
    const styles = {
        bmBurgerButton: {
            position: 'relative',
            width: '1.5em',
            height: '1.5em',
        },
        bmBurgerBars: {
            background: '#BE5739',
            height: '10%'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '1.5em',
            width: '1.5em'
        },
        bmCross: {
            background: '#ffffff'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%',
            top: '0'
        },
        bmMenu: {
            background: '#be5f39',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
            overflow: 'hidden'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItem: {
            color: '#ffffff',
            textDecoration: 'none',
            padding: '0.8em',
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)',
            left: '0',
            top: '0'
        }
    }

    return (
        <Grid container className="nav-bar" alignItems="center" justifyContent="center" >
            <Grid item xs={10}>
                <div style={{ display: "flex", flexDirection: "row", alignItems:"start" }}>
                    <img  className="yarnball" src={yarnball}></img>
                    <p id="sojo-title">
                        <a href="/user/home">Sojo</a>
                    </p>
                </div>
            </Grid>
            <Grid item xs={2}>
                <SlideMenu right styles={styles}>
                    <a className="menu-item" href="/user/home">Home</a>
                    <a className="menu-item" href="/user/current_orders">Current Orders</a>
                    <a className="menu-item" href="/user/completed_orders">Marked as Complete</a>
                    <a className="menu-item" href="/user/past_orders">Past Orders</a>
                    <br></br>
                    <a className="menu-item" href="/logout">Log out</a>
                </SlideMenu>
            </Grid>
        </Grid>
    )
}