/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining service status
 */
export enum ServiceStatus {
    READY = 'ready',
    ASK_CUSTOMER = 'ask_customer',
    AWAITING_CUSTOMER_RESPONSE = 'awaiting_customer_response',
    IN_PROGRESS = 'in_progress',
    QC_READY = 'qc_ready',
    REDO_FOLLOWING_QC = 'redo_following_qc',
    DONE = 'done',
    LOST = 'lost',
    REFUNDED = 'refunded',
}
