import { Grid } from "@mui/material";
import { Key, useEffect, useState } from "react";
import { getSeamsterPastOrders } from "../api/endpoints";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { OrderInfoBox } from "../components/OrderInfoBox";
import { OrderWithPickupShift, SeamsterPastOrders } from "../sojoApi"

export const PastOrders = () => {
    const [pastOrders, setPastOrders] = useState<SeamsterPastOrders>();

    useEffect(() => {
        const pastOrdersResponse = getSeamsterPastOrders();
        pastOrdersResponse.then((orders) => {
            if (orders !== undefined) {
                setPastOrders(orders.data);
            }
        });
    }, []);

    return (
        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }} className="App">
            {pastOrders?.past_orders?.sort((recent, oldest) => (recent.tracking[0].updated_at > oldest.tracking[0].updated_at) ? 1 : -1) !== undefined
                ? <>
                    <Grid container>
                        <Grid item xs={12}>
                            <h1 style={{ marginBottom: "0px" }}>Past Orders</h1>
                        </Grid>
                        {/* <Grid item xs={10}>
                            <p>Monthly earnings:</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p><b>£{(pastOrders.previous_month_earning / 100).toFixed(2)}</b></p>
                        </Grid> */}
                        <Grid item xs={12}>
                            <p style={{ marginTop: "8px", marginBottom: "6px", fontSize: "18px" }}>You have completed <span style={{ fontWeight: 600 }}>{pastOrders?.past_order_count}</span> orders with Sojo.</p>
                            <br></br>
                        </Grid>
                        <Grid item xs={12}>
                            {pastOrders.past_orders?.map(function (order: OrderWithPickupShift, i: Key) {
                                return <OrderInfoBox order={order} key={i}></OrderInfoBox>
                            })}
                        </Grid>
                    </Grid>
                </>
                : <LoadingSpinner></LoadingSpinner>}
        </div >
    )
}