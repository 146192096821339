import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { refreshUserAuth } from "../Auth";
import { SeamsterPricingUpdateRequest, SeamsterUpdateRequest } from "../models/Models";
import { Order } from "../sojoApi";


const API_ENDPOINT = process.env.REACT_APP_SOJO_API_ENDPOINT;

export const login = async (
    email: string,
    password: string
) => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/seamster_token`,
            method: "POST",
            data: {
                email: email,
                password: password
            },
            headers: { "Content-Type": "application/json" }
        }
    );
};

export const refreshLogin = async () => {
    // this is the only time singleApiCall should be called directly
    return singleApiCall(
        {
            url: `${API_ENDPOINT}/seamster/refresh_seamster_token`,
            method: "POST"
        }
    )
};

export const getSeamsterDetails = async () => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/seamster_details`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            }
        }
    )
};

export const getSeamsterCurrentOrders = async () => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/orders`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            }
        }
    )
}

export const getSeamsterPastOrders = async () => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/past_orders`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            }
        }
    )
}

export const updateOrderMarkReadyForCollection = async (order_id: string) => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/completed_order?order_id=${order_id}`,
            method: "PUT",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            }
        }
    )
}

export const updateSeamsterDetails = async (
    seamsterUpdateRequest: SeamsterUpdateRequest
) => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/seamster_details`,
            method: "PUT",
            data: seamsterUpdateRequest,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`
            }
        }
    );
};

export const updatePricing = async (
    seamsterPricingUpdateRequest: SeamsterPricingUpdateRequest
) => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/update_prices`,
            method: "PUT",
            data: seamsterPricingUpdateRequest,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`
            }
        }
    );
};

export const updateOrder = async (
    order: Order
) => {
    return makeApiCall(
        {
            url: `${API_ENDPOINT}/seamster/update_seamster_order`,
            method: "POST",
            data: order,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`
            }
        }
    );
};

const makeApiCall = async (
    request: AxiosRequestConfig
) => {
    try {
        return await singleApiCall(request);
    } catch (error) {
        if (await refreshUserAuth()) {
            request.headers = { ...request.headers, Authorization: `Bearer ${getAccessToken()}` }
            const response = await singleApiCall(request);
            if (response !== undefined) {
                return response;
            }
        }
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        if (window.location.pathname !== "/login" && window.location.pathname !== "/") window.location.replace("/login");
    }
};

// should only be called directly by refreshLogin
const singleApiCall = async (request: AxiosRequestConfig) => {
    const response = await axios(request);
    if (response.statusText === "OK" || (response.status >= 200 && response.status <= 299)) {
        return response;
    } else {
        // do something
    }
}

export const getAccessToken = () => {
    return window.localStorage.getItem("accessToken");
}