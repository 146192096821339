/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpsizingUnits } from './UpsizingUnits';

/**
 * Core details for all upsizing alterations
 */
export type UpsizingDetails = {
    service_type?: UpsizingDetails.service_type;
    units: UpsizingUnits;
    value: number;
};

export namespace UpsizingDetails {

    export enum service_type {
        UPSIZING = 'upsizing',
    }


}

