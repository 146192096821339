/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining order status
 */
export enum OrderStatus {
    AWAITING_PAYMENT = 'awaiting_payment',
    AWAITING_PICKUP = 'awaiting_pickup',
    IN_PROGRESS = 'in_progress',
    AWAITING_DELIVERY = 'awaiting_delivery',
    FINISHED = 'finished',
    CANCELLED = 'cancelled',
    AWAITING_STORE_COLLECTION = 'awaiting_store_collection',
}
