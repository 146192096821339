/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Core details for all repairs
 */
export type RepairDetails = {
    service_type?: RepairDetails.service_type;
    repair_location: string;
    repair_details: string;
};

export namespace RepairDetails {

    export enum service_type {
        REPAIR = 'repair',
    }


}

