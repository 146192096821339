import { Box, Button, Grid, TextField } from "@mui/material";
import { ChangeEvent, Key, useEffect, useState } from "react"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { OrderWithPickupShift, ItemImages } from "../sojoApi";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const OrderInfoBox = ({
  order,
  doneFunction,
  updateItemsFunction,
}: {
  order: OrderWithPickupShift;
  doneFunction?: React.MouseEventHandler<HTMLButtonElement>;
  showSave?: boolean;
  updatePriceFunction?: (e: any, save: boolean) => {};
  updateItemsFunction?: (
    order: OrderWithPickupShift,
    save: boolean
  ) => {};
}) => {
  const [showSave, setShowSave] = useState(false);
  const [toggleImage, setToggleImage] = useState<boolean>(false);
  const [beforeURL, setBeforeURL] = useState<(string | undefined)[]>(order.items.map(item => item.item_images?.before_image_url));
  const [afterURL, setAfterURL] = useState<(string | undefined)[]>(order.items.map(item => item.item_images?.after_image_url));
  const [updatedOrder, setUpdatedOrder] = useState<OrderWithPickupShift>(order);


  
  const toggleDownImageUpload = () => {
    setToggleImage(!toggleImage);
  };
  const setTwoDecimalPlaces = (e: any) => {
    e.target.value = parseFloat(e.target.value).toFixed(2);
  };
  function toSentenceCase(str: string) { 
    if (typeof str !== 'string') {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const partnerBrandName = order.type.includes('partner-') ? order.type.replace(/partner-/g, '').replace(/-/g, ' ') : undefined;

  useEffect(() => {
    let orderCopy: OrderWithPickupShift = JSON.parse(JSON.stringify(updatedOrder));
    if (partnerBrandName) {
      orderCopy.items.forEach((item) => {
        if (item.brand_name !== undefined) {
          item.brand_name = toSentenceCase(partnerBrandName);
          setUpdatedOrder(orderCopy);

        }
      });
    }
  }, []);
  
  const handleBrandNameSubmit = (e: any, index: number) => {
    let orderCopy: OrderWithPickupShift = JSON.parse(JSON.stringify(updatedOrder)); 
    orderCopy.items[index].brand_name = toSentenceCase(e.target.value);

 if (partnerBrandName  &&  partnerBrandName === e.target.value ){
      orderCopy.items[index].brand_name = toSentenceCase(partnerBrandName)
    }
    setUpdatedOrder(orderCopy);
  };

  const handleTailorNotesSubmit = (e: any, index: number) => {
    let orderCopy: OrderWithPickupShift = JSON.parse(JSON.stringify(updatedOrder));  // copy by value
    orderCopy.items[index].item_notes = e.target.value;
    setUpdatedOrder(orderCopy);
  };

  const handleImgChange = async (e: ChangeEvent<HTMLInputElement>, itemIndex: number, beforeImg: boolean) => {
    let orderCopy: OrderWithPickupShift = JSON.parse(JSON.stringify(updatedOrder));

    if (e.target.files) {
      const file = e.target.files[0];
      if (beforeImg) {
        let URLCopy = JSON.parse(JSON.stringify(beforeURL));
        URLCopy[itemIndex] = URL.createObjectURL(file);
        setBeforeURL(URLCopy);
      } else {
        let URLCopy = JSON.parse(JSON.stringify(afterURL));
        URLCopy[itemIndex] = URL.createObjectURL(file);
        setAfterURL(URLCopy);
      }

      const url: any = undefined;
      if (url !== undefined) {
        // update an existing item in the items array.
        let images = orderCopy.items[itemIndex].item_images;
        if (images !== undefined && images !== null) {
          if (beforeImg) {
            images.before_image_url = url.location;
          } else {
            images.after_image_url = url.location;
          }
        } else {
          if (beforeImg) {
            images = {
              "before_image_url": url.location,
              "after_image_url": ""
            } as ItemImages
          } else {
            images = {
              "before_image_url": "",
              "after_image_url": url.location
            } as ItemImages
          }
          orderCopy.items[itemIndex].item_images = images;
        }

        if (updateItemsFunction) {
          updateItemsFunction(orderCopy, true);
        }
        setUpdatedOrder(orderCopy);
      } else {
        alert("There was an error uploading the image. Please try again.");
      }
    }
  };

  const handleCompleteButtonClick = (e: any) => {

    // Check if any of the items in the order don't have a brand_name value
    let missingBrandName = updatedOrder.items.map(x => (x.brand_name || "").length).includes(0);
    
    const missingBeforeImages = updatedOrder.items.map(x => (x.item_images?.before_image_url || "").length).includes(0);
    const missingAfterImages = updatedOrder.items.map(x => (x.item_images?.after_image_url || "").length).includes(0);
    if (missingBrandName) {
      alert(
        "Please ensure the brand name is entered for all items on this order before marking as complete."
      );
    } else if (missingBeforeImages || missingAfterImages) {
      alert(
        "Please ensure all images are uploaded for this order before marking as complete."
      );
    } else {
      doneFunction
        ? doneFunction(e)
        : console.error("doneFunction not defined");
    }
  };
 

  return (
    <form
      className="price-update"
      id={order.order_id}
      onSubmit={(e: any) => {
        e.preventDefault();
        setShowSave(!showSave);
        if (updateItemsFunction) updateItemsFunction(updatedOrder, showSave);
      }}
    >
      <Grid container className="order-info-box">
        <Grid
          container
          className="order-info-heading totals-info"
          alignItems="center"
        >
          <div style={{ width: "100%" }}>
            <div> 
              {partnerBrandName ? (
                <div style={{ marginBottom: order.items[0].tier && "9pt" }}>
                  <span className="brand-name">
                    <span className="brand-text" style={{ marginBottom: "20px" }}>
                      {partnerBrandName}
                    </span>
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
            {order.items[0].tier === "tier2" ? (
              <span className="premium-service">
                <span className="premium-text" style={{ marginBottom: "20px" }}>
                  Premium order
                </span>
                </span>            
            ) : (
              <></>
            )}
            
           </div>
          </div>
          <Grid item sm={10} xs={9}>
            <h1 style={{ marginBottom: 0, paddingTop: "6px" }}>
              Order #{order.collection_ref}
            </h1>
            {order.status === "finished" ? (
              <div>
                {" "}
                <p
                  style={{
                    marginTop: 4,
                    marginBottom: 4,
                    fontWeight: 400,
                    lineHeight: "14pt",
                    fontSize: "10pt",
                    color: "black",
                  }}
                >
                  Completeted order received: <br />
                  <span>
                    Order received:{" "}
                    {order.pickup_shift ? (
                      <span style={{ color: "#be5f39", fontWeight: "600" }}>
                        {new Date(
                          order.pickup_shift.start_time
                        ).toLocaleDateString("en-GB", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          weekday: "long",
                        })}
                      </span>
                    ) : (
                      <span style={{ color: "#be5f39", fontWeight: "600" }}>
                        N/A
                      </span>
                    )}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: 2,
                    fontWeight: 400,
                    lineHeight: "14pt",
                    fontSize: "10pt",
                    color: "black",
                  }}
                >
                  {" "}
                  Customer name:{" "}
                  <span
                    style={{
                      fontWeight: 500,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {order.dropoff_address.full_name}
                  </span>
                </p>
              </div>
            ) : (
              <>
                {" "}
                <h3
                  style={{
                    marginBottom: 0,
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  <span>
                    Customer name:{" "}
                    <span
                      style={{
                        color: "#be5f39",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {order.dropoff_address.full_name}
                    </span>
                  </span>
                </h3>
                <h3
                  style={{ marginTop: 6, fontWeight: "400", fontSize: "16px" }}
                >
                  <span>
                    Order received:{" "}
                    {order.pickup_shift ? (
                      <span style={{ color: "#be5f39", fontWeight: "600" }}>
                        {new Date(
                          order.pickup_shift.start_time
                        ).toLocaleDateString("en-GB", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          weekday: "long",
                        })}
                      </span>
                    ) : (
                      <span style={{ color: "#be5f39", fontWeight: "600" }}>
                        N/A
                      </span>
                    )}
                  </span>
                </h3>
              </>
            )}
          </Grid>
          <Grid className="pencil-edit" item xs={2}>
            {order.status === "in_progress" ? (
              <>
                <Button type="submit" className="sojo-button hollow-button">
                  {showSave ? (
                    <>
                      <span>Save</span>
                      <FontAwesomeIcon icon={faFloppyDisk} />
                    </>
                  ) : (
                    <>
                      <span>Edit</span>
                      <FontAwesomeIcon icon={faPencil} />
                    </>
                  )}
                </Button>
              </>
            ) : (
              <p>{order.paid ? "Paid" : ""}</p>
            )}
          </Grid>
        </Grid>
        {updatedOrder.items.map(function (item, i: number) {
          return (
            <Grid container className="order-info" key={i}>
              <Grid item xs={12}>
                <p>
                  <b>{item.item_name}</b> - {item.item_description}
                </p>
              </Grid>
              <Grid item xs={12}> 
                <p style={{ marginTop: 0, marginBottom: "12px" }}>
                  <span style={{ fontWeight: 600 }}>Brand</span> -{" "}

                  <span> {item.brand_name ? item.brand_name : partnerBrandName ? toSentenceCase(partnerBrandName) : null}</span> 
                </p>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}> 
                <TextField
                  className="editable-text"
                  style={{ marginBottom: "10px", width: "100%" }} 
                  variant={!showSave ? "standard" : undefined}
                  disabled={!showSave}
                  defaultValue={item.brand_name ? item.brand_name : partnerBrandName ? toSentenceCase(partnerBrandName) : undefined} 
                  id="brand-name"
                  name="brand-name"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => { handleBrandNameSubmit(e, i); }}
                ></TextField>
              </Grid>

              <Grid item xs={12}> 
                <div style={{ marginTop: 0, marginBottom: "7px" }}>
                  <p style={{ fontWeight: 600, width: "100%", marginBottom: "0px", marginTop: 4 }}>Tailor Notes:</p>
                  <p style={{ marginTop: 4 }}>{item.item_notes ? item.item_notes : null}</p>
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}> 
                <TextField
                  className="editable-text"
                  variant={!showSave ? "standard" : undefined}
                  disabled={!showSave}
                  defaultValue={item.item_notes ? item.item_notes : undefined}
                  id="editable-text"
                  name="tailor-notes"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => { handleTailorNotesSubmit(e, i); }}
                  style={{ marginBottom: "16px", width: "100%", display: !showSave ? "none" : "block" }} 
                  multiline
                ></TextField>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  border: "solid 2px black",
                  background: "#b1b1b151",
                  borderRadius: "10px",
                }}
              >
                <Grid
                  onClick={toggleDownImageUpload}
                  item
                  xs={12}
                  className="accordian-class"
                  style={
                    toggleImage
                      ? {
                        padding: "10px 2px",
                        fontWeight: 400,
                        background: "black",
                        color: "white",
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "8px",
                      }
                      : {
                        padding: "10px 2px",
                        fontWeight: 400,
                        background: "white",
                        color: "black",
                        display: "flex",
                        justifyContent: "space-between",
                      }
                  }
                >
                  <span style={{ marginLeft: "8px" }}>
                    Item before + after images{" "}
                  </span>
                  <span style={{ paddingRight: "8px" }}>
                    {toggleImage ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  {toggleImage ? (
                    <Grid
                      container
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        paddingBottom: "18px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          margin: "auto",
                          width: "90%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {(item.item_images?.before_image_url || beforeURL[i]) && (
                          <Box mt={1} textAlign="start" width="95%">
                            <div className="image-title-font">
                              {"ITEM BEFORE TAILORING"}
                            </div>
                            <img
                              style={{ marginTop: "0px" }}
                              src={beforeURL[i] || item.item_images?.before_image_url}
                              alt={beforeURL[i] || item.item_images?.before_image_url}
                              width="100%"
                            />
                          </Box>
                        )}

                        <div
                          style={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "start",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={(e) => handleImgChange(e, i, true)}
                            accept="image/*, .jpg"
                            type="file"
                            id={item._id + "before"}
                            name="before-image"
                            style={{ display: "none" }}
                          />
                          <label htmlFor={item._id + "before"}>
                            <Button
                              id={"change-photo-btn"}
                              variant="contained"
                              style={{
                                marginTop: "6px",
                                marginBottom: "18px",
                                borderRadius: "30px",
                                fontWeight: 500,
                              }}
                              component="span"
                            >
                              UPLOAD
                            </Button>
                          </label>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          margin: "auto",
                          width: "90%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {(item.item_images?.after_image_url || afterURL[i]) && (
                          <Box mt={1} textAlign="start" width="95%">
                            <div className="image-title-font">
                              {"ITEM AFTER TAILORING"}
                            </div>
                            <img
                              style={{ marginTop: "0px" }}
                              src={afterURL[i] || item.item_images?.after_image_url}
                              alt={afterURL[i] || item.item_images?.after_image_url}
                              width="100%"
                            />
                          </Box>
                        )}

                        <div
                          style={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "start",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={(e) => handleImgChange(e, i, false)}
                            accept="image/*, .jpg"
                            type="file"
                            id={item._id + "after"}
                            name="after-image"
                            style={{ display: "none" }}
                          />
                          <label htmlFor={item._id + "after"}>
                            <Button
                              id={"change-photo-btn"}
                              variant="contained"
                              style={{
                                marginTop: "6px",
                                marginBottom: "18px",
                                borderRadius: "30px",
                                fontWeight: 500,
                              }}
                              component="span"
                            >
                              UPLOAD
                            </Button>
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <p>
                  <b>Services:</b>
                </p>
              </Grid>
              <Grid item xs={12}>
                {item.services.map(function (service, key: Key) {
                  return (
                    <Grid container className="service-details" key={key}>
                      <Grid item sm={10} xs={9}>
                        <p>
                          <span style={{ textTransform: "capitalize" }}>
                            {service.service_type}
                          </span>{" "}
                          - {service.name}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="sizing-option"
                        style={{ marginTop: -5 }}
                      >
                        {service.details?.service_type === "alteration" ? (
                          service.details.fitting_choice === "match" ? (
                            <p style={{ fontSize: "14px", marginLeft: "6px" }}>
                              <span
                                style={{ color: "#be5f39", fontWeight: "800" }}
                              >
                                *
                              </span>{" "}
                              Matched item -{" "}
                              {service.details.matched_item_description}
                            </p>
                          ) : service.details.fitting_choice === "measure" ? (
                            <p style={{ fontSize: "14px", marginLeft: "6px" }}>
                              <span
                                style={{ color: "#be5f39", fontWeight: "800" }}
                              >
                                *
                              </span>{" "}
                                Measurement notes -{" "}
                              {service.details.measurement_notes}
                            </p>
                          ) :  (
                            <p style={{ fontSize: "14px", marginLeft: "6px" }}>
                              <span
                                style={{ color: "#be5f39", fontWeight: "800" }}
                              >
                                *
                              </span>{" "}
                              The customer has pinned the item
                            </p>
                          )
                        ) : service.details?.service_type === "repair" ? (
                          <>
                            <p>
                              Repair location -{" "}
                              {service.details.repair_location}
                            </p>
                            {service.details.repair_details !== "" ? (
                              <>
                                <p style={{marginBottom: 0}}>Repair details:</p>
                                <div style={{ whiteSpace: "pre-wrap", marginBottom: "30px", paddingLeft: "10px", borderLeft: "1px solid black" }}>
                                  {service.details.repair_details}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : service.details?.service_type === "upsizing" ? (
                          <p>
                            Upsizing amount - {service.details.value}{" "}
                            {service.details.units}
                          </p>
                        ) : (
                          <p>Unknown</p>
                        )}
                      </Grid>
                      <Grid item xs={12} className="note-to-seamster">
                        {service.details?.service_type === "alteration" ? (
                          service.details.note_to_seamster !== undefined &&
                            service.details.note_to_seamster !== null ? (
                            <>
                              <p><b>Notes from customer:</b></p>
                              <div style={{ whiteSpace: "pre-wrap", marginBottom: "30px", paddingLeft: "10px", borderLeft: "1px solid black" }}>
                                {service.details.note_to_seamster}
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
        {order.status === "in_progress" ? (
          <Grid item className="seamster-done" xs={12}>
            <Button
              disabled={showSave}
              id={order.order_id}
              // hide this button
              style={{ display: "none", fontWeight: 600, marginTop: "6px" }}
              className="sojo-button"
              fullWidth
              variant="contained"
              onClick={(e) => {
                handleCompleteButtonClick(e);
              }}
            >
              Mark order as complete
            </Button>
          </Grid>
        ) : order.status === "awaiting_delivery" ? (
          <Grid item className="seamster-done" xs={12}>
            <Button
              disabled
              style={{ fontWeight: 600, marginTop: "6px" }}
              className="ready-disabled-button"
              fullWidth
              variant="outlined"
            >
              Order awaiting collection
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </form>
  );
};

