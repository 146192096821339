import axios from "axios";
import jwtDecode from "jwt-decode";
import { getSeamsterDetails, refreshLogin } from "./api/endpoints";

export const refreshUserAuth = async () => {
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (refreshToken) {        
        const decodedRefreshToken: any = jwtDecode(refreshToken);
        const refreshExpirationDate = decodedRefreshToken.exp;
        if (refreshExpirationDate * 1000 > new Date().getTime()) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + refreshToken;
            const response = await refreshLogin();
            if (response !== undefined) {
                axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.access_token;
                window.localStorage.setItem('accessToken', response.data.access_token);
                return true;
            }
        }
    }
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    
    return false;
}
