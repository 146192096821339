import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from './screens/Login';
import { createTheme, ThemeProvider } from '@mui/material';
import { Home } from './screens/Home';
import PrivateOutlet from './PrivateOutlet';
import { Logout } from './screens/Logout';
import { AuthProvider } from './context/AuthContext';
import { CurrentOrders } from './screens/CurrentOrders';
import { PastOrders } from './screens/PastOrders';
import { OpenAPI } from "./sojoApi";
import { getAccessToken } from './api/endpoints';
import { CompletedOrders } from './screens/CompletedOrders';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: normal;
          src: url(./assets/fonts/Inter-Medium.tff) format('truetype');
        }
      `,
    },
  },
});


function App() {

  OpenAPI.BASE = process.env.REACT_APP_SOJO_API_ENDPOINT || "https://staging.api.sojo.uk";
  OpenAPI.WITH_CREDENTIALS = true;
  OpenAPI.TOKEN = getAccessToken() || "";

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/user" element={<PrivateOutlet />}>
              {/* Routes requiring auth go here */}
              <Route path="home" element={<Home />} />
              <Route path="current_orders" element={<CurrentOrders />} />
              <Route path="completed_orders" element={<CompletedOrders />} />
              <Route path="past_orders" element={<PastOrders />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
