import { Button, TextField, Grid } from "@mui/material";
import axios from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getSeamsterDetails, login } from "../api/endpoints";
import { refreshUserAuth } from "../Auth";
import { AuthContext } from "../context/AuthContext";

const defaultValues = {
  email: "",
  password: "",
};

export const Login = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(defaultValues);
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    getSeamsterDetails().then((response) => {
      if (response) {
        navigate("/user/home");
      } else {
        refreshUserAuth().then((response) => {
          if (response) {
            navigate("/user/home");
          }
        })
      }
    })
  }, [])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response = await login(formValues.email, formValues.password);
    if (response !== undefined) {
      window.localStorage.setItem("accessToken", response.data.access_token);
      window.localStorage.setItem("refreshToken", response.data.refresh_token);
      axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.access_token;
      setIsAuthenticated(true);
      navigate("/user/home");
    }
  };

  return (
    <div className="Login">
      <Grid container className="login-title" alignItems="center" justifyContent="center">
        <Grid item xs={10} >
          <h1>Welcome to Sojo Seamsters.</h1>
        </Grid>
        <Grid item xs={10}>
          <p>Login to access your profile</p>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <TextField id="email" name="email" onChange={handleInput} label="Email address" />
          </Grid>
          <Grid item xs={12}>
            <TextField id="password" name="password" type="password" onChange={handleInput} label="Password" />
          </Grid>
          <Grid item xs={12}>
            <Button className="sojo-button" type="submit" variant="contained">Log in</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
