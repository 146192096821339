import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export const Logout = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  
  useEffect(() => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
  });

  return (
    <Navigate to="/login" />
  );
};
