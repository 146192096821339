import { Button, Grid, TextField, styled } from "@mui/material";
import { Key, useEffect, useState } from "react";
import { getSeamsterCurrentOrders, updateOrder, updateOrderMarkReadyForCollection } from "../api/endpoints";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { OrderInfoBox } from "../components/OrderInfoBox";
import { Modal } from 'react-overlays';
import { SeamsterOrders, Order, OrderWithPickupShift } from "../sojoApi";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 100000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5; 
`;

const ConfirmModal = styled(Modal)`
  position: fixed;
  width: 80%;
  z-index: 100001;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

export const CurrentOrders = () => {
    const renderBackdrop = (props: any) => <Backdrop {...props} />;

    const [currentOrders, setCurrentOrders] = useState<SeamsterOrders>();
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [targetOrderId, setTargetOrderId] = useState<string>();
    const [collectionRefSearch, setCollectionRefSearch] = useState<string>();

    useEffect(() => {
        getSeamsterCurrentOrders().then((orders) => {
            if (orders !== undefined) {
                let filteredOrders = orders.data;
                filteredOrders.current_orders = orders.data.current_orders.filter((order: any) =>
                    order.status === "in_progress"
                );
                setCurrentOrders(filteredOrders);
            }
        });
    }, []);

    const doneFunction = (e: any) => {
        setShowModal(true);
        setTargetOrderId(e.target.id)
    }

    const markOrderReadyForCollection = async (e: any) => {
        if (targetOrderId !== undefined) {
            const response = await updateOrderMarkReadyForCollection(targetOrderId);
            if (response !== undefined) {
                setShowModal(false);
                setTargetOrderId(undefined);
                window.location.reload();
            }
        }
        console.log("Unknown error");
    }

    const updateItemsFunction = async (order: OrderWithPickupShift, save: boolean) => {
        if (save) {
            updateOrder(order as Order).catch((error) => { console.error(error) })
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }} className="App">
            {currentOrders?.current_orders.sort((recent: any, oldest: any) => (recent.tracking[0].updated_at < oldest.tracking[0].updated_at) ? 1 : -1) ?
                <>
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: "10pt" }}>
                            <h1 style={{ marginBottom: "0px" }}>Current Orders</h1>
                            <p style={{ marginTop: "8px", marginBottom: "6px", fontSize: "18px" }}>You have <span style={{ fontWeight: 600, marginTop: "0px" }}>{currentOrders.current_orders?.length}</span> active orders.</p>
                        </Grid>
                        <Grid item xs={12} justifyContent={"center"} style={{ marginBottom: "20px" }}>
                            <TextField
                                className="search"
                                variant={"outlined"}
                                id="search"
                                name="search"
                                placeholder="Search by collection reference"
                                onInput={(e: any) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                }}
                                onChange={(e) => { setCollectionRefSearch(e.target.value); }}
                                fullWidth
                                type="number"
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            {currentOrders?.current_orders.map((order: OrderWithPickupShift, i: Key) => {
                                if (order.collection_ref.includes(collectionRefSearch || "")) {
                                    return <OrderInfoBox order={order} doneFunction={doneFunction} updateItemsFunction={updateItemsFunction} key={i}></OrderInfoBox>
                                } else {
                                    return <></>
                                }
                            })}
                        </Grid>
                    </Grid>
                </>
                : <LoadingSpinner></LoadingSpinner>}
            <ConfirmModal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setTargetOrderId(undefined);
                }}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <div>
                    <h1>Are you sure?</h1>
                    <p>
                        Please click to confirm that this order is ready for collection.
                    </p>
                    <Button variant="text" className="sojo-button hollow-button" onClick={() => {
                        setShowModal(false);
                        setTargetOrderId(undefined);
                    }}>Cancel</Button>
                    <Button variant="contained" className="sojo-button button-right" id={targetOrderId} onClick={markOrderReadyForCollection}>Confirm</Button>
                </div>
            </ConfirmModal>

            <ConfirmModal
                show={showErrorModal}
                onHide={() => {
                    setShowModal(showErrorModal);
                }}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <div>
                    <h1>Price change requested</h1>
                    <p>
                        The requested price change has been sent to our team as it's over £10 more than the initial price.
                    </p>
                    <p>
                        Please do not proceed with this order until we contact you to confirm the change.
                    </p>
                    <Button variant="text" className="sojo-button hollow-button" onClick={() => {
                        setShowErrorModal(false);
                    }}>Close</Button>
                </div>
            </ConfirmModal>
        </div >
    )
}