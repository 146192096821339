/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information for order status update notifications
 */
export type NotificationOrderStatusUpdate = {
    'content-available'?: number;
    notification_type?: NotificationOrderStatusUpdate.notification_type;
    order_id: string;
};

export namespace NotificationOrderStatusUpdate {

    export enum notification_type {
        ORDER_STATUS_UPDATE = 'order_status_update',
    }


}

