import { Button, Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { getSeamsterCurrentOrders, getSeamsterDetails, updateSeamsterDetails } from "../api/endpoints";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { SeamsterCurrentOrders, SeamsterDetails } from "../models/Models";
import yarnball from '../assets/images/yarnball.png';
import { useNavigate } from "react-router-dom";

const defaultValues = {
    email: "",
    phone: "",
    note_to_rider: ""
};

export const Home = () => {
    const navigate = useNavigate();
    
    const [seamsterDetails, setSeamsterDetails] = useState<SeamsterDetails>();
    const [currentOrders, setCurrentOrders] = useState<SeamsterCurrentOrders>();

    const [editDisabled, setEditDisabled] = useState(true);
    const [formValues, setFormValues] = useState(defaultValues);

    useEffect(() => {
        const seamsterDetailsResponse = getSeamsterDetails();
        seamsterDetailsResponse.then((details) => {
            if (details !== undefined) {
                setSeamsterDetails(details.data);
                setFormValues({ email: details.data.email, phone: details.data.phone, note_to_rider: details.data.note_to_rider })
            }
        });

        const currentOrdersReponse = getSeamsterCurrentOrders();
        currentOrdersReponse.then((orders) => {
            if (orders !== undefined) {
                setCurrentOrders(orders.data);
            }
        });
    }, []);

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        console.log(formValues);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!editDisabled) {
            const response = await updateSeamsterDetails(formValues);
            if (response !== undefined) {
                if (seamsterDetails?.email !== formValues.email) {
                    navigate("/login");
                }
                window.location.reload();
            }
        }
        setEditDisabled(!editDisabled);
    };

    return (
        <div className="App">
            {seamsterDetails !== undefined && currentOrders !== undefined
                ? <>
                    <img className="yarnball" src={yarnball}></img>
                    <Grid container>
                        <Grid item xs={12}>
                            <h1>Hello {seamsterDetails.name}</h1>
                            <p>You have {currentOrders.upcoming_order_count} upcoming orders.
                                You have {currentOrders.current_order_count} active orders.</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <a className="sojo-button" href="/user/current_orders">
                                <Button className="sojo-button" fullWidth variant="contained">
                                    Go to active orders
                                </Button>
                            </a>
                        </Grid>
                        <Grid item className="headed-info" xs={12}>
                            <p className="subheading">Address:</p>
                            <p>{seamsterDetails.address.full_name}</p>
                            <p>{seamsterDetails.address.line1}</p>
                            {seamsterDetails.address.line2 ? <p>{seamsterDetails.address.line2}</p> : <></>}
                            {seamsterDetails.address.city ? <p>{seamsterDetails.address.city}</p> : <></>}
                            <p>{seamsterDetails.address.postcode}</p>
                        </Grid>
                        <form className="editable-fields" onSubmit={handleSubmit}>
                            <Grid item className="headed-info" xs={12}>
                                <p className="subheading">Contact information:</p>
                                <TextField variant="standard" disabled className="text-field" id="name" name="name" value={seamsterDetails.name}></TextField>
                                <TextField variant={editDisabled ? "standard" : undefined} onChange={handleInput} disabled={editDisabled} className="text-field" id="email" name="email" value={formValues.email}></TextField>
                                <TextField variant={editDisabled ? "standard" : undefined} onChange={handleInput} disabled={editDisabled} className="text-field" id="phone" name="phone" value={formValues.phone}></TextField>
                            </Grid>
                            <Grid item className="headed-info" xs={12}>
                                <p className="subheading">Note to riders:</p>
                                <TextField variant={editDisabled ? "standard" : undefined} onChange={handleInput} disabled={editDisabled} className="text-field" id="note_to_rider" name="note_to_rider" value={formValues.note_to_rider || ""}></TextField>
                                <br></br>
                                <Button variant="text" type="submit" className="sojo-button hollow-button flex-left">{editDisabled ? "Edit" : "Save"}</Button>
                            </Grid>
                        </form>
                    </Grid>
                </>
                : <LoadingSpinner></LoadingSpinner>}

        </div >
    );
};