import { createContext, FC, useState } from "react";

interface AuthContextState {
    // set the type of state you want to handle with context e.g.
    isAuthenticated: boolean;
    setIsAuthenticated: (auth: boolean) => void;
}

const defaultState = {
    isAuthenticated: false,
    setIsAuthenticated: () => {}
}
//set an empty object as default state
export const AuthContext = createContext<AuthContextState>(defaultState);

export const AuthProvider: FC = ({ children }) => {
    const [isAuthenticated, setIsAuthenticatedState] = useState(defaultState.isAuthenticated);

    const setIsAuthenticated = (auth: boolean) => {
        setIsAuthenticatedState(auth);
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    )
}